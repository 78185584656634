import React from 'react'

import Layout from '../components/layout'
import Con from '../images/contact.jpg'

const Contact = () => (
  <Layout>
    <div id="contact">
      <h1>CONTACT</h1>
      <img src={Con} alt="Contact" />
      <p>If you wish to get in contact, please email us at <i>darkroomlaboratories@gmail.com</i></p>
    </div>
  </Layout>
)

export default Contact
